import React, { useEffect, useState } from "react";
import LineupBuilderBanner from "../../img/LineupBuilderBanner.png";
import SDLoading from "../util/SDLoading";
import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";
import GLOBAL_MARGIN from "../util/margin";
import { formatDistance } from "date-fns";
import { findPriceForUserUnit } from "../util/formatMoney";
import { Button } from "../util/button";
import LineSeparator from "../../img/line-separator.svg";

function MyTradesBox(props) {
  const cards = props.cards;
  return (
    <div className={"flex flex-row justify-center py-3 space-x-2"}>
      {props.ether > 0 && (
        <div className={"self-center"}>
          <p className={"font-semibold text-base"}>{findPriceForUserUnit(props.ether, props.fiat, props.preferredUnit)}</p>
        </div>
      )}
      {cards !== null && cards.length > 0 && props.ether > 0 && <p className={"text-base font-semibold self-center"}>+</p>}
      <div className={"flex flex-row space-x-2 justify-center"}>
        {cards !== null &&
          cards.map((c, i) => {
            if (i < 4) {
              return (
                <div className={"h-28"} key={c.card.TokenId}>
                  <img src={c.card.PictureUrl} className={"w-full h-full object-contain"} />
                </div>
              );
            } else return null;
          })}
        {cards !== null && cards.length > 4 && <p className={"text-base font-semibold self-center"}>+ {cards.length - 4} more</p>}
      </div>
    </div>
  );
}

function MyTrades(props) {
  const [trades, setTrades] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState("received");

  useEffect(() => {
    if (props.user.sorareSlug !== "" && props.user.sorareSlug !== undefined) {
      getTrades();
    }
  }, [props.user]);

  const getTrades = (r, m) => {
    setLoading(true);
    let refresh = r || false;
    let mo = m || mode;
    props
      .fetch("/apiv2/offers/trades?manager=" + props.user.sorareSlug + "&refresh=" + refresh + "&mode=" + mo)
      .then((response) => response.json())
      .then((res) => {
        if (Array.isArray(res)) {
          setTrades(res);
        } else if (res.error) {
          console.error(res);
        }
        setLoading(false);
      })
      .catch(
        errorCatcher(() => {
          setLoading(false);
        }),
      );
  };

  const activeTextClass = "font-semibold text-base text-brand";
  const inactiveTextClass = "font-semibold text-base text-textGrey4";

  if (loading === true) {
    return (
      <div>
        <SDLoading />
      </div>
    );
  } else {
    const tradesAreEmpty = trades === null || trades === undefined || trades.length === 0;
    return (
      <div className={"space-y-8"}>
        <div className={"space-y-2"}>
          <div>
            <div
              className={"w-full lg:h-48 h-32 relative opacity-100"}
              style={{
                backgroundImage: "url('" + LineupBuilderBanner + "')",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className={"z-40 self-center flex mx-16 lg:h-48 h-32 align-middle flex-row justify-between"}>
                <p className={"self-center text-5xl font-headers font-semibold text-left text-white z-10"}>My trades</p>
                <div className={"self-center flex flex-row space-x-4"}>
                  <div className={"flex flex-row bg-grey-f2 space-x-12 self-center rounded-lg shadow py-2 px-12"}>
                    <div
                      className={"cursor-pointer self-center text-center w-5/12"}
                      onClick={() => {
                        getTrades(undefined, "received");
                        setMode("received");
                      }}
                    >
                      <p className={mode === "received" ? activeTextClass : inactiveTextClass}>Received</p>
                    </div>
                    <div className={"self-center w-1 h-6"}>
                      <img src={LineSeparator} />
                    </div>
                    <div
                      className={"cursor-pointer self-center text-center w-5/12"}
                      onClick={() => {
                        getTrades(undefined, "sent");
                        setMode("sent");
                      }}
                    >
                      <p className={mode === "sent" ? activeTextClass : inactiveTextClass}>Sent</p>
                    </div>
                  </div>
                  <Button label={"Refresh"} onClick={() => getTrades(true)} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {!tradesAreEmpty && (
          <div className={GLOBAL_MARGIN}>
            <table
              className={
                "z-0 min-h-48 overflow-hidden border-collapse rounded-t-lg rounded-b-lg table-fixed w-full bg-white whitespace-no-wrap mx-auto"
              }
            >
              <thead>
                <th className="rounded-tl-lg cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-2/12 font-bold uppercase text-xs">
                  {mode === "sent" ? "Sent to" : "Sent by"}
                </th>
                <th className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-4/12 font-bold uppercase text-xs">
                  You send
                </th>
                <th className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-1/12 font-bold uppercase text-xs"></th>
                <th className="cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 text-left w-4/12 font-bold uppercase text-xs">
                  You receive
                </th>
                <th className="text-center rounded-tr-lg cursor-pointer text-white bg-brand-black border-b border-gray-200 mx-auto py-3 px-4 text-left w-1/12 font-bold uppercase text-xs"></th>
              </thead>
              <tbody>
                {trades?.map((t) => {
                  return (
                    <tr className={"border-b border-grey-e5"}>
                      <td>
                        <div className={"flex flex-col pl-2"}>
                          <div className={"flex flex-row space-x-4"}>
                            <div className={"w-10 h-19 self-center"}>
                              <img
                                className={"w-full h-full object-contain"}
                                src={mode === "sent" ? t.manager_receiving.PictureUrl : t.manager_sending.PictureUrl}
                              />
                            </div>
                            <div className={"self-center"}>
                              <p className={"font-semibold text-sm"}>
                                {mode === "sent" ? t.manager_receiving.Nickname : t.manager_sending.Nickname}
                              </p>
                              <p className={"text-xs font-medium"}>
                                Sent {formatDistance(new Date(t.offer.CreationDate), new Date(), { addSuffix: true })}
                              </p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <MyTradesBox
                          cards={mode === "sent" ? t.players_sent : t.players_received}
                          ether={mode === "sent" ? t.offer.SendAmountInWei : t.offer.ReceiveAmountInWei}
                          fiat={mode === "sent" ? t.sent_amount_fiat : t.received_amount_fiat}
                          preferredUnit={props.user.preferredUnit}
                          offer={t}
                        />
                      </td>
                      <td>
                        <p className={"text-center font-semibold text-base"}>{"<=>"}</p>
                      </td>
                      <td>
                        <MyTradesBox
                          cards={mode === "sent" ? t.players_received : t.players_sent}
                          ether={mode === "sent" ? t.offer.ReceiveAmountInWei : t.offer.SendAmountInWei}
                          fiat={mode === "sent" ? t.received_amount_fiat : t.sent_amount_fiat}
                          preferredUnit={props.user.preferredUnit}
                          offer={t}
                        />
                      </td>
                      <td>
                        <div className={"flex flex-col space-y-1"}>
                          <a href={"/offer/" + t.offer.OfferId} target={"_blank"} rel="noreferrer">
                            <p className={"font-semibold text-center text-sm text-brand"}>Details</p>
                          </a>
                          <a href={"/tradeBuilder?offerId=" + t.offer.OfferId} target={"_blank"} rel="noreferrer">
                            <p className={"font-semibold text-center text-sm text-brand"}>Open in builder</p>
                          </a>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {tradesAreEmpty && (
          <div className={GLOBAL_MARGIN}>
            <div className={"text-base font-semibold text-center xl:w-8/12 2xl:w-8/12 mx-auto space-y-2"}>
              <p>Nothing to see here... yet! Click on the refresh button to get your ongoing private offers.</p>
              <p>
                If refreshing doesn&apos;t work, please log out and log back in with Sorare and try again. If nothing appears, you probably
                don&apos;t have any ongoing private offers... receive some, come back and refresh!
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withUser(MyTrades);
